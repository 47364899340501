import { Button, Descriptions } from 'antd'
import {
  // useContext,
  useEffect,
  useState,
} from 'react'
import { CSVLink } from 'react-csv'
import { useStrings } from '../../hooks/useStrings'
import { createCSVData } from '../../utils/createCSVData'
import * as XLSX from 'xlsx'
// import { SettingsContext } from '../../contexts/Settings'

const Summary = ({ data, columns, summary, subTable }) => {
  const { lng } = useStrings('components', 'Table')
  // const { settings } = useContext(SettingsContext)
  const [totals, setTotals] = useState([])

  const totalKwh = lng('totalKWH')

  useEffect(() => {
    const newTotals = summary?.keys?.map(key => {
      let totalByKey = data?.reduce((acc, row) => acc + Number(row?.[key]), 0)

      switch (key) {
        // case 'price':
        //   return [
        //     lng('totalPrice'),
        //     `${
        //       settings?.PriceSettings?.currencyFix === 'prefix'
        //         ? ` ${settings?.PriceSettings?.currency}`
        //         : ''
        //     }${totalByKey?.toFixed(2).toString().replace('.', ',')}${
        //       settings?.PriceSettings?.currencyFix === 'suffix'
        //         ? ` ${settings?.PriceSettings?.currency}`
        //         : ''
        //     }`,
        //   ]
        case 'kwh':
          return [totalKwh, totalByKey?.toFixed(6)?.replace('.', ',')]
        case 'to':
          return ['To', summary?.date?.to]
        case 'from':
          return ['From', summary?.date?.from]
        default:
          return ['Total', totalByKey]
      }
    })

    setTotals(newTotals)
  }, [summary.keys, totalKwh])

  const excelData = createCSVData({
    data,
    headers: columns,
    childHeaders: subTable?.headers ?? [],
    extra: totals,
  })

  const downloadxls = (e, data) => {
    e.preventDefault()
    const ws = XLSX.utils.json_to_sheet(data)
    const wb = XLSX.utils.book_new()

    XLSX.utils.book_append_sheet(wb, ws, 'ChargerSessions')
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, 'ChargerSessions.xlsx')
  }

  return (
    <Descriptions
      title={lng('summaryTitle')}
      size="small"
      extra={
        <div>
          <Button
            key="csv"
            htmlType="button"
            type="primary"
            style={{
              display: 'block',
              marginBottom: '3px',
            }}>
            <CSVLink
              data={excelData}
              filename={'ChargerSessions.csv'}
              target="_blank">
              {lng('exportCSV')}
            </CSVLink>
          </Button>
          <Button
            onClick={e => {
              downloadxls(e, excelData)
            }}
            key="excel"
            htmlType="button"
            type="primary"
            style={{
              display: 'block',
            }}>
            {lng('exportExcel')}
          </Button>
        </div>
      }>
      {totals?.map((total, i) => (
        <Descriptions.Item key={i} label={total[0]}>
          {total[1]?.replace(',', '.')}
        </Descriptions.Item>
      ))}
    </Descriptions>
  )
}

export default Summary
