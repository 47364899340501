import React, { useEffect, useState } from 'react'
import CPOTable from '../../../components/Table'
import { useStrings } from '../../../hooks/useStrings'
import { useUsersEnergy } from '../../../hooks/useUsersEnergy'
import { createTableHeaders } from '../../../utils/createTableHeaders'
import formatUserEnergy from '../../../utils/formatUserEnergy'
import ExpandedRowRender from './ExpandedRowRender'

const KWhPerUserList = ({ values }) => {
  const [
    { data: sessions, isLoading: isSessionsLoading, isError: isSessionsError },
    { data: events, isLoading: isEventsLoading, isError: isEventsError },
    { data: subUsers, isLoading: isSubUsersLoading, isError: isSubUsersError },
  ] = useUsersEnergy(values)
  const { lng } = useStrings('pages', 'ChargeAmount')

  const [chargedByUser, setChargedByUser] = useState()

  const headers = createTableHeaders(
    lng,
    [
      'username',
      'name',
      'kwh',
      // 'price'
    ],
    // settings,
  )

  const childHeaders = createTableHeaders(
    lng,
    [
      'rfId',
      'kwh',
      // 'price'
    ],
    // settings,
  )

  useEffect(() => {
    if (
      sessions &&
      events &&
      subUsers &&
      !isSessionsLoading &&
      !isSessionsError &&
      !isEventsLoading &&
      !isEventsError &&
      !isSubUsersLoading &&
      !isSubUsersError
    ) {
      const formattedData = formatUserEnergy({
        RfidEvents: events?.RfidEvents,
        sessions,
        userInfo: subUsers,
      })

      setChargedByUser(formattedData)
    }
  }, [
    sessions,
    isSessionsLoading,
    isSessionsError,
    events,
    isEventsLoading,
    isEventsError,
    subUsers,
    isSubUsersLoading,
    isSubUsersError,
  ])

  return (
    <CPOTable
      data={chargedByUser}
      headers={headers}
      subTable={{ headers: childHeaders }}
      summary={{
        keys: [
          'kwh',
          'from',
          'to',
          // 'price'
        ],
        date: {
          from: values?.date?.[0].format('YYYY-MM-DD HH:mm:ss'),
          to: values?.date?.[1].format('YYYY-MM-DD HH:mm:ss'),
        },
      }}
      expandable={{
        expandedRowRender: (record, index) => (
          <ExpandedRowRender
            record={record}
            index={index}
            headers={childHeaders}
            // settings={settings}
          />
        ),
      }}
      key="primary"
    />
  )
}

export default KWhPerUserList
