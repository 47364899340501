import { Button, Card, Col, Form, Row } from 'antd'
import CPOForm from '../../../components/Form'
import StringInput from '../../../components/Form/Inputs/StringInput'
import { required } from '../../../components/Form/validations'
import Meta from '../../../components/Layout/Meta'
import PageHeader from '../../../components/Layout/PageHeader'
import { useAddCharger } from '../../../hooks/Charger'
import { useStrings } from '../../../hooks/useStrings'

const AddCharger = () => {
  const { lng } = useStrings('pages', 'AddCharger')
  const { mutateAsync: addCharger, isLoading } = useAddCharger()

  const onSubmit = async values => {
    await addCharger(values)
  }

  return (
    <div>
      <Meta title={lng('metaTitle')} />
      <PageHeader
        backTo="/chargers"
        pageHeaderProps={{ title: lng('metaTitle') }}
      />
      <Row div className="padding">
        <Col span={12}>
          <Card>
            <CPOForm onSubmit={onSubmit} resetOnSubmit>
              <StringInput
                name="name"
                label={lng('chargerNameLabel')}
                placeholder={lng('chargerPlaceholder')}
                validate={[required]}
              />
              <StringInput
                name="serialNumber"
                label={lng('serialNumberLabel')}
                placeholder="ECABC123"
                validate={[required]}
              />
              <Form.Item>
                <Button
                  loading={isLoading}
                  style={{ float: 'right' }}
                  type="primary"
                  htmlType="submit">
                  {lng('addCharger')}
                </Button>
              </Form.Item>
            </CPOForm>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default AddCharger
