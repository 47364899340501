import { useStrings } from '../../hooks/useStrings'
import Meta from '../../components/Layout/Meta'
import KWhPerCharger from './KWhPerCharger'
import KWhPerUser from './KWhPerUser'
import KWhPerSession from './KWhPerSession'
import CPOTabs from '../../components/CPOTabs'
import { useRfIdTags } from '../../hooks/RfId'
import { useEffect, useState } from 'react'
import PageHeader from '../../components/Layout/PageHeader'

const Reports = () => {
  const { lng } = useStrings('pages', 'Reports')
  const {
    data: rfIdTags,
    isLoading: isTagsLoading,
    isError: isTagsError,
  } = useRfIdTags()

  const [showRFID, setShowRFID] = useState(true)

  useEffect(() => {
    if (rfIdTags && !isTagsLoading && !isTagsError) {
      const show = rfIdTags?.some(tag => tag?.RfidTags?.length > 0)

      setShowRFID(show)
    }
  }, [rfIdTags, isTagsLoading, isTagsError])

  const tabs = [
    {
      label: lng('kWhPerSession'),
      component: <KWhPerSession />,
    },
    ...(showRFID
      ? [
          {
            label: lng('kWhPerUser'),
            component: <KWhPerUser />,
          },
        ]
      : []),
    {
      label: lng('kWhPerCharger'),
      component: <KWhPerCharger />,
    },
  ]

  return (
    <div>
      <PageHeader pageHeaderProps={{ title: lng('metaTitle') }} />
      <Meta title={lng('metaTitle')} />
      <div className="padding">
        <CPOTabs tabs={tabs} />
      </div>
    </div>
  )
}

export default Reports
