import { formatISO } from 'date-fns'
import { useQueries } from 'react-query'
import fetchJson from '../utils/fetchJson'
import { eventKey, fetchEvents } from './Event'
import { useEaseeItems } from './Item'
import { fetchSubUsers, subUserKey } from './SubUser'
import { useAuth } from './Auth'

export const useUsersEnergy = values => {
  const { user } = useAuth()
  const { data: items } = useEaseeItems()

  const from = formatISO(new Date(values?.date[0]))
  const to = formatISO(new Date(values?.date[1]))
  const chargerIds = items?.map(item => item?.id)

  return useQueries([
    {
      queryKey: ['sessions'],
      queryFn: async () => {
        return await fetchJson(
          `${process.env.REACT_APP_ENEGIC_API_ENDPOINT}/easeehome/getchargersessions`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/*+json',
              'X-Authorization': user?.TokenInfo?.Token,
            },
            body: JSON.stringify({
              ChargerId: chargerIds,
              From: from,
              To: to,
            }),
          },
        )
      },
      enabled: !!to && !!from && !!user && chargerIds?.length > 0,
    },
    {
      queryKey: [eventKey],
      queryFn: fetchEvents(from, to, user),
      enabled: !!to && !!from && !!user,
    },
    {
      queryKey: [subUserKey],
      queryFn: fetchSubUsers(user),
      enabled: !!user,
    },
  ])
}
