import { Form, Button, Alert } from 'antd'
import moment from 'moment'
import CPOForm from '../../components/Form'
import { required } from '../../components/Form/validations'
import DateInput from '../../components/Form/Inputs/DateInput'
import CPOTable from '../../components/Table'
import ItemsInput from '../../components/Form/Inputs/ItemsInput'
import { useStrings } from '../../hooks/useStrings'
import { createTableHeaders } from '../../utils/createTableHeaders'
// import { SettingsContext } from '../../contexts/Settings'
import {
  // useContext,
  useEffect,
  useState,
} from 'react'
import { format } from 'date-fns'
import { useChargerSessions } from '../../hooks/ChargerSessions'
import { useItemWithSharesByChargerId } from '../../hooks/Item'
import { useSubUsers } from '../../hooks/SubUser'
import { useEventsByCharger } from '../../hooks/Event'

const ChargingHistory = () => {
  const { lng } = useStrings('pages', 'ChargingHistory')
  // const { settings } = useContext(SettingsContext)
  const [formattedSessions, setFormattedSessions] = useState(null)
  const [submittedValues, setSubmittedValues] = useState(null)

  const {
    data: sessions,
    isLoading,
    isError,
    mutateAsync: getSessions,
    error,
  } = useChargerSessions()
  const {
    data: shares,
    isLoading: isSharesLoading,
    isError: isSharesError,
  } = useItemWithSharesByChargerId(submittedValues?.chargerId)
  const {
    data: subUsers,
    isLoading: isUserLoading,
    isError: isUserError,
  } = useSubUsers()
  const {
    data: events,
    isLoading: isEventLoading,
    isError: isEventError,
  } = useEventsByCharger(submittedValues?.chargerId)

  useEffect(() => {
    if (
      sessions &&
      !isLoading &&
      !isError &&
      events &&
      !isEventLoading &&
      !isEventError &&
      shares &&
      !isSharesLoading &&
      !isSharesError &&
      subUsers &&
      !isUserLoading &&
      !isUserError
    ) {
      const formattedData = sessions?.[0]?.ChargerSessions?.map((row, i) => {
        const carConnected = new Date(row.carConnected)
        const start = format(carConnected, 'yyyy-MM-dd HH:mm')
        const stop = format(new Date(row.carDisconnected), 'yyyy-MM-dd HH:mm')

        // Find Auth Event
        const event = events?.RfidEvents?.find(ev => {
          const timestamp = new Date(ev.Timestamp)

          return (
            timestamp - carConnected >= 0 &&
            timestamp - carConnected <= 3000 &&
            ev?.EventType === 'enable'
          )
        })

        let user = null
        // Check if Auth event has valid UserId
        if (event && event?.UserId > -1) {
          // Find User
          user = subUsers?.find(usr => usr?.User?.UserId === event?.UserId)
        } else {
          // Check if there is only one shared User
          if (shares?.length === 1) {
            user = subUsers?.find(
              usr => usr?.User?.UserId === shares?.[0]?.SharedToUserId,
            )
          }
        }

        // const price = settings?.PriceSettings?.price
        //   ? Number(settings?.PriceSettings?.price) * row.kiloWattHours
        //   : 0

        return {
          key: i,
          start,
          stop,
          username: user?.User?.Username,
          name: `${user?.UserInfo?.FirstName ?? ''} ${
            user?.UserInfo?.LastName ?? ''
          }`,
          kwh: row.kiloWattHours,
          // price,
        }
      })
      setFormattedSessions(formattedData)
    }
  }, [
    sessions,
    isLoading,
    isError,
    events,
    isEventLoading,
    isEventError,
    shares,
    isSharesLoading,
    isSharesError,
    subUsers,
    isUserLoading,
    isUserError,
  ])

  const headers = createTableHeaders(
    lng,
    [
      'start',
      'stop',
      'username',
      'name',
      'kwh',
      // 'price'
    ],
    // settings,
  )

  const onSubmit = async values => {
    await getSessions(values)
    setSubmittedValues(values)
  }

  return (
    <div className="padding">
      <CPOForm onSubmit={onSubmit}>
        <div style={{ width: '400px' }}>
          <ItemsInput
            name="chargerId"
            label={lng('chargerIDLabel')}
            placeholder={lng('chargerIDLabel')}
            validate={[required]}
          />
          <DateInput
            name="date"
            label={lng('timeRangeLabel')}
            validate={[required]}
            inputProps={{
              style: { width: '100%' },
              showTime: {
                defaultValue: [
                  moment('00:00:00', 'HH:mm:ss'),
                  moment('00:00:00', 'HH:mm:ss'),
                ],
                format: 'HH',
              },
            }}
          />
          {isError && (
            <Form.Item>
              <Alert message={error} type="error" />
            </Form.Item>
          )}
        </div>

        <Form.Item>
          <Button loading={isLoading} type="primary" htmlType="submit">
            {lng('generateBtn')}
          </Button>
        </Form.Item>
      </CPOForm>

      <div>
        <CPOTable
          summary={{
            keys: [
              'kwh',
              'from',
              'to',
              // 'price'
            ],
            date: {
              from: submittedValues?.date?.[0].format('YYYY-MM-DD HH:mm:ss'),
              to: submittedValues?.date?.[1].format('YYYY-MM-DD HH:mm:ss'),
            },
          }}
          data={formattedSessions}
          headers={headers}
        />
      </div>
    </div>
  )
}

export default ChargingHistory
