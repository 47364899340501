import './tabs.css'
import { Tabs } from 'antd'

const { TabPane } = Tabs

const CPOTabs = ({ tabs = [] }) => {
  return (
    <div className="card-container">
      <Tabs type="card" defaultActiveKey="0">
        {tabs?.map((item, i) => {
          return (
            <TabPane tab={item?.label} key={i}>
              {item.component}
            </TabPane>
          )
        })}
      </Tabs>
    </div>
  )
}

export default CPOTabs
