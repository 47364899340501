import './global.css'

import { Route, Routes } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'

import LanguageProvider from './contexts/Language'
import EnegicProvider from './contexts/EnegicUser'
import { LoginItems, RoutesItems } from './components/Layout/Menus'
import ProtectedLayout from './components/Layout'
// // import SettingsProvider from './contexts/Settings'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import ItemsProvider from './contexts/Items'

const Routing = () => {
  const queryClient = new QueryClient()

  // Worth thinking about how you can prevent fetches when user isnt defined
  // ^ Semi-fixed, still think we should have a "Protected path" style solution
  return (
    <QueryClientProvider client={queryClient}>
      <LanguageProvider>
        <HelmetProvider>
          <EnegicProvider>
            <ItemsProvider>
              <Routes>
                <Route element={<ProtectedLayout />}>
                  {RoutesItems?.map(item => (
                    <Route
                      key={item.key}
                      path={item.path}
                      element={item.element}
                    />
                  ))}
                </Route>
                {LoginItems?.map(item => (
                  <Route
                    key={item.key}
                    path={item.path}
                    element={item.element}
                  />
                ))}
              </Routes>
            </ItemsProvider>
          </EnegicProvider>
        </HelmetProvider>
      </LanguageProvider>
      {process.env.NODE_ENV !== 'production' && (
        <ReactQueryDevtools initialIsOpen={false} />
      )}
    </QueryClientProvider>
  )
}

export default Routing
