import { Table } from 'antd'
import Summary from './Summary'

const CPOTable = ({
  data,
  headers,
  summary = false,
  subTable = false,
  ...rest
}) => {
  if (!data) return null

  return (
    <Table
      {...rest}
      dataSource={data}
      columns={headers}
      title={
        summary
          ? () => (
              <Summary
                summary={summary}
                subTable={subTable}
                data={data}
                columns={headers}
              />
            )
          : null
      }
    />
  )
}

export default CPOTable
