import { useMutation, useQuery } from 'react-query'
import fetchJson from '../utils/fetchJson'
import { useContext } from 'react'
import { EnegicContext } from '../contexts/EnegicUser'

export const useLogin = () => {
  return useMutation(async values => {
    return fetchJson(
      `${process.env.REACT_APP_ENEGIC_API_ENDPOINT}/createtoken`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: values?.username,
          password: values?.password,
          ...(values?.impersonate ? { Impersonate: values?.impersonate } : {}),
        }),
      },
    )
  })
}

export const useAuth = () => {
  return useContext(EnegicContext)
}

export const useUserInfo = () => {
  const { user } = useAuth()

  return useQuery(
    ['userinfo'],
    async () =>
      await fetchJson(
        `${process.env.REACT_APP_ENEGIC_API_ENDPOINT}/getuserinfo/`,
        {
          method: 'GET',
          headers: {
            'X-Authorization': user?.TokenInfo?.Token,
          },
        },
      ),
    {
      enabled: !!user,
    },
  )
}
